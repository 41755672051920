import React from "react";
import Layout from "../../components/Layout";

// eslint-disable-next-line
export default () => (
  <Layout>
    <section className="section">
      <div className="container is-max-desktop">
        <div className="content">
          <h1>フォーム送信完了</h1>
          <p>お問い合わせありがとうございます。お問い合わせ内容を確認の上、担当者より折り返しご連絡させて頂きます。</p>
        </div>
      </div>
    </section>
  </Layout>
);
